<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 v-html="brandNm + ' (으)로 프렌차이즈 연결 신청을 취소할까요?'" />
            <div class="single-file-btn">
                <p @click="$emit('closeCancel')">아니오</p>
                <p id="color-text" @click="cancelConnect()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "axios";

export default {
  name: 'Cancel',
  props: {
    brandId: String,
    brandNm: String
  },
  methods: {
    cancelConnect () {
      var brandId = this.brandId;
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/requestBrandConnectCancel", { brandId, spaceId }, { headers })
        .then((res) => {
          // console.log(res.data);
          this.$emit('closeCancel');
          this.$emit('getList');
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
