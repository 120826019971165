<template>
    <div class="wrap">

        <Connect :spaceNm="spaceNm" :brandNm="brandNm" :brandId="brandId" v-if="isConnect" @closeConnect="isConnect = false"
                 @getList="firstList()" />
        <cancel :brandNm="brandNm" :brandId="brandId" v-if="isCancel" @closeCancel="isCancel = false"
                @getList="firstList(), getBrandListData(), search = ''" />
        <div class="space_pc">
            <disconnect :brandNm="brandNm" :brandId="brandId" v-if="isDisconnect" @closeDisconnect="isDisconnect = false"
                        @getList="firstList(), getBrandListData(), search = ''" />
            <section class="main myspace w_940">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1>브랜드 연결</h1>
                        <div @click="clickPre()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content frenchise">
                            <div class="search" v-if="Connected">
                                <input type="text" placeholder="브랜드명을 입력하세요." v-model="search" @keyup.enter="getBrandList()" />
                                <img src="/media/img/search_icon.svg" alt="" @click="getBrandList()" />
                            </div>
                            <div class="search_brand" style="flex-wrap: wrap;">
                                <template v-for="(item, index) in brandList">
                                    <div :key="index">
                                        <img v-bind:src="baseImgUrl + item.brandImg.substr(12)" v-if="item.brandImg != null" alt="" />
                                        <img src="/media/img/car.png" v-else-if="item.brandImg === null && item.brandType === 'Car'" alt="" />
                                        <img src="/media/img/cafe.png"
                                             v-else-if="item.brandImg === null && item.brandSector === 'sector_00001'" alt="">
                                        <img src="/media/img/food.png"
                                             v-else-if="item.brandImg === null && item.brandSector === 'sector_00002'" alt="">
                                        <img src="/media/img/drink.png"
                                             v-else-if="item.brandImg === null && item.brandSector === 'sector_00003'" alt="">
                                        <img src="/media/img/hospital.png"
                                             v-else-if="item.brandImg === null && item.brandSector === 'sector_00004'" alt="">
                                        <img src="/media/img/Facilities.png"
                                             v-else-if="item.brandImg === null && item.brandSector === 'sector_00005'" alt="">
                                        <img src="/media/img/thumnail_hotel.png"
                                             v-else-if="item.brandImg === null && item.brandSector === 'sector_00006'" alt="">
                                        <img src="/media/img/Group 485982.png"
                                             v-else-if="item.brandImg === null && item.brandSector === 'sector_00007'" alt="">
                                        <img src="/media/img/Group 485982.png" v-else-if="item.brandImg === null && item.brandSector == null"
                                             alt="">
                                        <p v-html="item.brandNm" />
                                        <span id="eorl" v-if="item.requestAt === 'Y'">승인대기</span>
                                        <span id="dhksfy" v-else-if="item.acceptAt === 'Y'">승인완료</span>
                                        <span id="connection" v-else @click="clickConnect(item)">연결</span>
                                        <div class="brand_setting" v-if="item.requestAt === 'Y' || item.acceptAt === 'Y'"
                                             @click.once="clickSetBox()">
                                            <div class="span_box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                            <div>
                                                <p style="margin-bottom:0px;" v-if="item.requestAt === 'Y'" @click="clickCancel(item)">신청취소</p>
                                                <p style="margin-bottom:0px;" v-else-if="item.acceptAt === 'Y'" @click="clickDisconnect(item)">
                                                    연결해제</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <p v-if="brandListAlert" class="brand-list-alert">
                                    <!-- '{{ search }}'에 대한 검색 결과가 없습니다. -->
                                    검색 결과가 존재하지 않습니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo" @click="hideTooltip">
            <!-- <ConnectM :brandNm="brandNm" :brandId="brandId" v-if="isConnect" @closeConnect="isConnect = false" @getList="firstList()"/> -->
            <!-- <cancelM :brandNm="brandNm" :brandId="brandId" v-if="isCancel" @closeCancel="isCancel = false" @getList="firstList(), getBrandListData(), search=''"/> -->
            <disconnectM :brandNm="brandNm" :brandId="brandId" v-if="isDisconnect" @closeDisconnect="isDisconnect = false"
                         @getList="firstList(), getBrandListData(), search = ''" />
            <div class="back_dim" @click="hideConnectBox"></div>
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                    </a>
                    <h2>
                        브랜드 연결
                    </h2>
                    <div class="brand_tooltip">
                        <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                             @mouseover.once="showTooltip()">
                            <path
                                d="M6 11C3.2385 11 1 8.7615 1 6C1 3.2385 3.2385 1 6 1C8.7615 1 11 3.2385 11 6C11 8.7615 8.7615 11 6 11ZM6 10C7.06087 10 8.07828 9.57857 8.82843 8.82843C9.57857 8.07828 10 7.06087 10 6C10 4.93913 9.57857 3.92172 8.82843 3.17157C8.07828 2.42143 7.06087 2 6 2C4.93913 2 3.92172 2.42143 3.17157 3.17157C2.42143 3.92172 2 4.93913 2 6C2 7.06087 2.42143 8.07828 3.17157 8.82843C3.92172 9.57857 4.93913 10 6 10ZM5.5 3.5H6.5V4.5H5.5V3.5ZM5.5 5.5H6.5V8.5H5.5V5.5Z"
                                fill="white" />
                        </svg>
                    </div>
                    <div class="tooltip">
                        <!-- <span></span> -->
                        <p style="margin-bottom:0px;">
                            <span>브랜드 연결이란?</span><br />
                            프랜차이즈 본사와 연결되는 기능으로, <br />
                            연결 시 프랜차이즈 사운드를 재생할 수 있습니다.
                        </p>
                    </div>
                </div>
            </div>
            <section class="main myspace h_100" style="overflow:auto;">
                <div class="main_box">
                    <div class="cs_main">
                        <div class="myspace_content frenchise">
                            <div class="search" v-if="Connected">
                                <input type="text" placeholder="브랜드명을 입력하세요." v-model="search" @keyup.enter="getBrandList()" />
                                <img src="/media/img/search_icon.svg" alt="" @click="getBrandList()" />
                            </div>
                            <div class="search_brand" style="flex-wrap: wrap;">
                                <template v-for="(item, index) in brandList">
                                    <div :key="index">
                                        <div>
                                            <img v-bind:src="baseImgUrl + item.brandImg.substr(12)" v-if="item.brandImg != null" alt="" />
                                            <img src="/media/img/car.png" v-else-if="item.brandImg == null && item.brandType === 'Car'"
                                                 alt="" />
                                            <img src="/media/img/cafe.png"
                                                 v-else-if="item.brandImg === null && item.brandSector === 'sector_00001'" alt="">
                                            <img src="/media/img/food.png"
                                                 v-else-if="item.brandImg === null && item.brandSector === 'sector_00002'" alt="">
                                            <img src="/media/img/drink.png"
                                                 v-else-if="item.brandImg === null && item.brandSector === 'sector_00003'" alt="">
                                            <img src="/media/img/hospital.png"
                                                 v-else-if="item.brandImg === null && item.brandSector === 'sector_00004'" alt="">
                                            <img src="/media/img/Facilities.png"
                                                 v-else-if="item.brandImg === null && item.brandSector === 'sector_00005'" alt="">
                                            <img src="/media/img/thumnail_hotel.png"
                                                 v-else-if="item.brandImg === null && item.brandSector === 'sector_00006'" alt="">
                                            <img src="/media/img/Group 485982.png"
                                                 v-else-if="item.brandImg === null && item.brandSector === 'sector_00007'" alt="">
                                            <img src="/media/img/Group 485982.png"
                                                 v-else-if="item.brandImg === null && item.brandSector == null" alt="">
                                            <div>
                                                <p style="margin-bottom:0px;" v-html="item.brandNm" />
                                                <span id="eorl" v-if="item.requestAt === 'Y'">승인대기</span>
                                                <span id="dhksfy" v-else-if="item.acceptAt === 'Y'">승인완료</span>
                                            </div>
                                        </div>
                                        <div class="brand_setting" v-if="item.requestAt === 'Y' || item.acceptAt === 'Y'">
                                            <div class="span_box" @click="getSettingM()">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                        <span id="connection" v-else @click="clickConnect(item)">연결</span>
                                        <div class="brand_setting_modal">
                                            <div>
                                                <p style="margin-bottom:0px;" v-if="item.requestAt === 'Y'" @click="clickCancel(item)">신청취소</p>
                                                <p style="margin-bottom:0px;" v-else-if="item.acceptAt === 'Y'" @click="clickDisconnect(item)">
                                                    연결해제</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <p v-if="brandListAlert" class="brand-list-alert">
                                    검색 결과가 존재하지 않습니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import Connect from "@/components/modal/Franchise/Connect.vue";
import Cancel from "@/components/modal/Franchise/Cancel.vue";
import Disconnect from "@/components/modal/Franchise/Disconnect.vue";
// import ConnectM from "@/components/modal/Franchise/ConnectM.vue";
// import CancelM from "@/components/modal/Franchise/CancelM.vue";
import DisconnectM from "@/components/modal/Franchise/DisconnectM.vue";
import $ from "jquery";
import axios from "axios";
import VueCookies from "vue-cookies";

export default defineComponent({
  data () {
    return {
      search: "",
      baseImgUrl: "https://www.aplayz.co.kr/media/upload",
      brandList: [],
      brandListData: [],
      isConnect: false,
      isDisconnect: false,
      isCancel: false,
      brandId: "",
      brandNm: "",
      Connected: true,
      brandListAlert: false,
      cancelStatus: 'Y'
    };
  },

  props: {
    spaceNm: String,
    spaceType: String
  },

  components: {
    Connect,
    Cancel,
    Disconnect,
    // ConnectM,
    // CancelM,
    DisconnectM
  },

  mounted () {
    this.set();
    this.firstList();
    this.getBrandListData();
  },

  updated () {
    this.set();
  },

  methods: {
    hideConnectBox () {
      $(".brand_setting_modal").css({ bottom: -100, transition: "all 0.5s" });
      $(".back_dim").hide();
    },
    async firstList () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/profile/getConnectBrand", { spaceId }, { headers })
        .then((res) => {
          this.brandList = res.data.result;
          this.brandList.length ? this.Connected = false : this.Connected = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getBrandListData () {
      // var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      var spaceType = this.spaceType;

      await axios
        .post("/api/profile/getBrandList", { spaceType }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.brandListData = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getBrandList () {
      if (this.search === '') {
        alert('검색어를 입력해주세요!');
      } else {
        this.brandList = this.brandListData.filter((brand) => {
          return (brand.brandNm.toLowerCase().includes(this.search.toLowerCase()));
        });
      }
      if (this.brandList.length === 0) {
        this.brandListAlert = true;
      } else {
        this.brandListAlert = false;
      }
    },
    getRefresh () {
      this.getBrandListData();
      this.set();
      this.brandList();
    },
    clickConnect (brand) {
      this.brandId = brand.brandId;
      this.brandNm = brand.brandNm;
      this.isConnect = true;
    },
    clickCancel (brand) {
      this.brandId = brand.brandId;
      this.brandNm = brand.brandNm;
      this.isCancel = true;
    },
    clickDisconnect (brand) {
      this.brandId = brand.brandId;
      this.brandNm = brand.brandNm;
      this.isDisconnect = true;
    },
    clickPre () {
      history.back();
    },
    set () {
      $(".span_box").siblings('div').hide();
      $(".back_dim").hide();
      $(".tooltip").hide();
    },
    clickSetBox () {
      $(document).off("click").on("click", ".span_box", (e) => {
        $(e.currentTarget).siblings('div').fadeToggle();
      });
    },
    showTooltip () {
      $(document).off("click").on("click", ".mo_header_box div svg", (e) => {
        $(".tooltip").fadeIn(300);
      });
    },
    hideTooltip () {
      $(".tooltip").fadeOut(300);
    },
    getSettingM () {
      $(document).off("click").on("click", ".brand_setting .span_box", () => {
        $(".back_dim").show();
        $(".brand_setting_modal").css({ bottom: 0, transition: "all 0.5s" });
      });
    }
  }
});
</script>

<style scoped>
.space_pc .search_brand img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.space_mo .search_brand img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.brand-list-alert {
  color: rgba(255, 255, 255, 0.64);
  width: 100%;
  text-align: center;
  margin-top: 10%;
}

.search_brand>div>span#eorl {
  width: auto;
  border-radius: 50px;
  padding: 2px 12px;
  font-size: 14px;
  background: rgba(156, 255, 110, 0.16);
  color: #98DB78;
}

.search_brand>div>span#dhksfy {
  width: auto;
  border-radius: 50px;
  padding: 2px 12px;
  font-size: 14px;
  color: #ccc;
  background: rgba(255, 255, 255, 0.16);
}

.search_brand>div>div:nth-child(1) span#eorl {
  color: #98DB78;
}

.brand_tooltip {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
}

@media all and (min-width: 501px) {
  .search_brand>div>p {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
